import { useContext } from 'react'
import { useRouter } from 'next/router'
import { GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_HEADER } from '../../../constants/gigya'
import { setupOriginUrlCookie } from '../../../helpers/cookies'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { useUser } from '../../../hook/useUser'
import { theme } from '../../../styles'

export function UserButtonStandard(): JSX.Element {
  const { asPath } = useRouter()
  const { hit } = useContext(TagCommanderContext)
  const { isLoggedIn, userProfile } = useUser()

  const urlRedirectAccount = isLoggedIn
    ? '/compte/'
    : `/compte/accueil/?serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_HEADER}`

  const handleAccountClick = () => {
    setupOriginUrlCookie(asPath)
    hit(
      {
        screen_clickableElementName: `header_mon-compte_${isLoggedIn ? '' : 'non'}_logue`,
      },
      { isClickEvent: true },
    )
    window.location.href = urlRedirectAccount
  }
  const title =
    isLoggedIn && userProfile?.profile?.firstName?.length ? 'Accéder à mon compte' : 'Se connecter'

  return (
    <>
      <a
        className={`UserButton flex items-center${isLoggedIn ? ' UserButton--connect' : ''}`}
        href="#"
        onClick={handleAccountClick}
        data-testid="btn-account"
        aria-label={title}
        title={title}
      >
        {isLoggedIn && userProfile?.profile?.firstName?.length ? (
          <p className="UserButton__Title">{userProfile.profile.firstName[0].toUpperCase()}</p>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            fill="none"
          >
            <path
              stroke={isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.white}
              strokeWidth="2"
              d="M4 22v-6h16v6"
            />
            <path
              fill={isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.white}
              fillRule="evenodd"
              d="M11.9 4.52a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
              clipRule="evenodd"
            />
            <path
              fill={isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.focusBlue}
              fillRule="evenodd"
              d="M17 7.52 q-0.5,4.52 -5 5 l 0 -2 q 3,-0.5 2.80,-3Z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </a>

      <style jsx>{`
        .UserButton {
          cursor: pointer;
          gap: 4px;
          border: 1px solid ${theme.cssVars.white};
          border-radius: 24px;
          padding: 6px;
          color: ${theme.cssVars.white};

          text-decoration: none;
          font-size: 16px;
          height: 32px;
          width: 32px;
        }

        .UserButton--connect {
          color: ${theme.cssVars.deepBlue};
          background-color: ${theme.cssVars.lightGrayBlue};
          padding: 0;
          font-weight: 700;
          justify-content: center;
        }

        .UserButton--connect {
          width: 32px;
        }

        .UserButton p {
          padding-top: 4px;
        }
      `}</style>
    </>
  )
}
